<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import SalaryDeductionManagement from "./SalaryDeductionManagement/index.vue";
// import EmployeeFiles from "./EmployeeFiles/index.vue";
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    SalaryDeductionManagement,
    // EmployeeFiles,
  },
  data() {
    return {};
  },
  methods: {},
  created() {},
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('salaryDeduction.title')"
    />

    <!-- start personal informations -->

    <SalaryDeductionManagement />

    <!-- end personal informations -->
  </Layout>
</template>
<style scoped></style>
